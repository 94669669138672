import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

// ===REDUX STORE IMPORTS===
import store from './store/index';
// ===COMPONENT IMPORTS===
import App from './components/App';
import './components/UI/Theme/ThemeColors.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
);

serviceWorkerRegistration.register();
reportWebVitals();
