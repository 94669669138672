import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { REDIRECT_URL_AFTER_LOGIN } from 'utils/constants';
import LoginForm from './Membership/UserRegistration/LoginForm/LoginForm';
import Footer from './Footer/Footer';
import Home from './HomePage/Home';

// COMPONENTS IMPORTS
const DashBoard = lazy(() => import('./DashBoard/DashBoard'));
const Team = lazy(() => import('./Team/Team'));
const PasswordReset = lazy(() =>
  import('./Membership/UserRegistration/PasswordReset/PasswordReset'),
);
const ChangePassword = lazy(() =>
  import('./Membership/UserRegistration/PasswordReset/ChangePassword'),
);

const RegistrationForm = lazy(() =>
  import('./Membership/UserRegistration/RegistrationForm/RegistrationForm'),
);
const CourseUnitPage = lazy(() => import('./CourseUnits/CourseUnitPage/CourseUnitPage'));
const CoursePage = lazy(() => import('./Courses/CoursePage/CoursePage'));
const UniversityPage = lazy(() => import('./Universities/UniversityPage/UniversityPage'));
const VerifyingAccount = lazy(() =>
  import('./Membership/UserRegistration/PasswordReset/VerifyingAccount'),
);
const AccountVerification = lazy(() =>
  import('./Membership/UserRegistration/PasswordReset/AccountVerification'),
);
const DocumentPage = lazy(() => import('./DocumentPage/DocumentPage'));

const AppRoutes = () => {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const isNotAdmin = !user?.roles?.includes('admin');
  const location = useLocation();

  const previousLocation = localStorage.getItem('previousLocation');

  const hasPreviousLocation =
    previousLocation?.length > 1 &&
    previousLocation !== '/register' &&
    previousLocation !== '/password-reset' &&
    previousLocation !== '/account-verification' &&
    previousLocation !== '/verifying-account' &&
    previousLocation !== '/change-password' &&
    previousLocation !== '/';
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route
          path='/dashboard/*'
          element={isAuthenticated ? <DashBoard /> : <Navigate to='/login' />}
        />
        <Route path='/:uni_name' element={<UniversityPage />} />
        <Route path='/:uni_name/:course_name' element={<CoursePage />} />
        <Route
          path='/:uni_name/:course_name/:course_unit_slug/:document_id'
          element={isAuthenticated ? <DocumentPage /> : <Navigate to='/login' />}
        />
        <Route path='/:uni_name/:course_name/:course_unit_slug' element={<CourseUnitPage />} />
        <Route
          path='/register'
          element={
            !isAuthenticated ? (
              <RegistrationForm title='Sign Up' />
            ) : (
              <Navigate to='/dashboard/user' />
            )
          }
        />
        <Route
          path='/login'
          element={
            isAuthenticated ? (
              <Navigate to={hasPreviousLocation ? -1 : REDIRECT_URL_AFTER_LOGIN} />
            ) : (
              <LoginForm />
            )
          }
        />
        <Route
          path='/admin-login'
          element={
            isAuthenticated && isNotAdmin ? (
              <Navigate to={hasPreviousLocation ? -1 : REDIRECT_URL_AFTER_LOGIN} />
            ) : (
              <LoginForm isSuperAdmin />
            )
          }
        />
        <Route
          path='/password-reset'
          element={!isAuthenticated ? <PasswordReset /> : <Navigate to='/dashboard/user' />}
        />
        <Route
          path='/reset-password/:pwdResetToken'
          element={!isAuthenticated ? <ChangePassword /> : <Navigate to='/dashboard/user' />}
        />
        <Route
          path='/account-verification-request'
          element={!isAuthenticated ? <AccountVerification /> : <Navigate to='/dashboard/user' />}
        />
        <Route
          path='/verify-account/:verificationToken'
          element={!isAuthenticated ? <VerifyingAccount /> : <Navigate to='/dashboard/user' />}
        />
        <Route path='/our-creative-team' element={<Team />} />
        <Route path='*' element={<h1>404</h1>} />
      </Routes>
      {location.pathname.includes('/dashboard') ? null : <Footer />}
    </>
  );
};

export default AppRoutes;
