import { GPA_APIS } from 'adapters';
import { useEffect, useState } from 'react';

const useTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUserTransactions = async () => {
    setLoading(true);
    const res = await GPA_APIS.auth.get.getUserTransactions();
    setTransactions(res.data.transactions);
    setLoading(false);
  };

  useEffect(() => {
    fetchUserTransactions();
  }, []);

  return { transactions, loading };
};

export default useTransactions;
