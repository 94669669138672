import React from 'react';

// ===MUI IMPORTS===
import { Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// ===COMPONENTS IMPORTS===
import { useSelector } from 'react-redux';

import classes from './ChatModal.module.css';

export const CHAT_ACTION_TYPE = {
  NEW_CHAT: 'NEW_CHAT',
  NEW_ROOM: 'NEW_ROOM',
  USER_SETTINGS: 'USER_SETTINGS',
  EDIT_ROOM: 'EDIT_ROOM',
  ADD_STUDENTS: 'ADD_STUDENTS',
};

const modalStyle = {
  bgcolor: 'background.paper',
};

const ChatModal = ({ open, handleClose, modalTitle, component }) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={modalStyle}
        className={`${classes.gpa__modal_wrapper} ${darkMode ? classes.gpa__dark_mode : ''}`}
      >
        <div className={classes.gpa__modal_close_button_wrapper}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          className={`${classes.gpa__modal_content_wrapper} ${
            darkMode ? classes.gpa__dark_mode : ''
          }`}
        >
          <div
            className={`${classes.gpa__modal_title} ${
              darkMode ? classes.gpa__dark_mode : ''
            } shadow-sm`}
          >
            <div className='flex item-center'>
              <h4>{modalTitle}</h4>
            </div>
          </div>
          {component}
        </div>
      </Box>
    </Modal>
  );
};

export default ChatModal;
